<template>
	<AddEditForm :headerTitle="$t('pages.warehouse.addWarehouse')" :onGoBack="onGoBack" :showDelete="false">
		<template v-slot:headerAddEdit>
			<button type="button" name="add-addWarehouse" class="btn btn-primary" @click="onSave">
				{{ $t('common.save') }}
			</button>
		</template>
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" v-if="editingWareHouse != null">
				<template v-slot:body>
					<CForm>
						<div role="group" class="form-group form-row">
							<label class="col-form-label col-sm-3 col-form-label">{{ $t('pages.warehouse.selectProduct') }} <small class="requiredInput">(*)</small></label>
							<div class="col-sm-9 input-group">
								<!--<vue-single-select v-model="selectedProduct"
												   :options="listProductName"
												   option-label="name"
												   :getOptionValue="getProductValue"
												   :placeholder="$t('pages.warehouse.selectProduct')"
												   :max-results="50" style="width:100%"></vue-single-select>-->
								<multiselect :options="productList"
											 v-model="selectedProduct"
											 :loading="isLoadingProducts"
											 :searchable="true"
											 :close-on-select="true"
											 :clear-on-select="false"
											 :multiple="false"
											 :placeholder="$t('common.typetosearch')"
											 :noOptionsText="$t('common.theListIsEmpty')"
											 :reset-after="false"
											 :local-search="false"
											 :internal-search="false"
											 @search-change="asyncFind"											 											
											 @input="getProductValue"
											 id="ajax"
											 label="name"
											 track-by="id">
								</multiselect>
							</div>
						</div>

						<CInput horizontal :value="productPackagingSpecification" readonly>
							<template #label>
								<div class="col-sm-3 col-form-label-">
									{{ $t('pages.product.packagingSpecification') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>						

						<CurrencyInput v-model="editingWareHouse.price" :label="$t('common.price')">
							<template v-slot:headerRight>
								<small class="requiredInput">(*)</small>
							</template>
						</CurrencyInput>

						<CInput :placeholder="$t('common.enterQuantity')" v-model="editingWareHouse.quantity"
								horizontal @keypress="$func.onlyNumbers">
							<template #label>
								<div class="col-sm-3 col-form-label-">
									{{ $t('common.quantity') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>						

						<CInput v-model="editingWareHouse.shipmentCode" horizontal>
							<template #label>
								<div class="col-form-label col-sm-3 col-form-label">
									{{ $t('pages.warehouse.shipmentCode') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>


						<div role="group" class="form-group form-row">
							<label class="col-form-label col-sm-3 col-form-label">{{ $t('pages.warehouse.dateOfManufacture') }}</label>
							<div class="col-sm-2 input-group">
								<date-picker v-model="editingWareHouse.dateOfManufacture" format="DD/MM/YYYY"></date-picker>
							</div>
						</div>

						<div role="group" class="form-group form-row">
							<label class="col-form-label col-sm-3 col-form-label">{{ $t('pages.warehouse.expirationDate') }}</label>
							<div class="col-sm-2 input-group">
								<date-picker v-model="editingWareHouse.expirationDate" format="DD/MM/YYYY"></date-picker>
							</div>
						</div>
					</CForm>
				</template>
			</Accordion>
		</template>
	</AddEditForm>
</template>

<script>    
    import { mapGetters, mapActions } from 'vuex'
	import i18n from '@/plugins/i18n'
	import CurrencyInput from '@/components/CurrencyInput.vue'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'
	//import VueSingleSelect from "vue-single-select";

	export default {
		name: 'warehousetadd',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				productPackagingSpecification: '',
				selectedProduct: null,
				isLoadingProducts: false,
				productList: []
			}
		},
        components: {
			CurrencyInput,
			Accordion,
			AddEditForm,
            //VueSingleSelect
        },
		computed: {
			...mapGetters('warehouse', ['editingWareHouse', 'hasError', 'errorMessage']),
            //...mapGetters('product', ['listProductName']),            
		},
		methods: {            
            onSave() {
                this.onAddWarehouse();
            },
            onGoBack() {
                this.$router.push('/warehouses/list');
			},
            onChangeProduct(event) {
				
				try {
					let pId = parseInt(event.target.value);
					if (pId > 0) {
                        let product = this.listProductName.filter(x => x.id === pId)[0];
						this.productPackagingSpecification = product.packagingSpecification;
                                              
                    }
				} catch (e) {
                    console.log('onChangeProduct ex: ', e)
				}
            },
			async onAddWarehouse() {

				try {
                    if (this.selectedProduct)
                        this.editingWareHouse.productId = this.selectedProduct.id;

                    if (this.editingWareHouse.productId === 0) {
                        this.$store.dispatch('alert/error', i18n.t('pages.warehouse.selectProduct'), { root: true });
                        return;
                    }
                  

                    if (this.editingWareHouse.price <= 0) {
                        this.$store.dispatch('alert/error', i18n.t('pages.product.enterPrice'), { root: true });
                        return;
					}

                    if (this.editingWareHouse.quantity <= 0) {
                        this.$store.dispatch('alert/error', i18n.t('pages.warehouse.enterQuantity'), { root: true });
                        return;
					}
                  

                    if (this.editingWareHouse.shipmentCode === '') {
                        this.$store.dispatch('alert/error', i18n.t('pages.warehouse.enterShipmentCode'), { root: true });
                        return;
					}
                   
                    var retVal = await this.$store.dispatch("warehouse/saveWareHouse");
                    if (retVal.data == true) {
						this.$router.push('/warehouses/list');
					}
					else {                       
                        var mess = i18n.t('common.unsuccessfull');
                        if (retVal.errorMessage != '') {
                            switch (retVal.errorMessage) {
                                case 'Invalid RetailUnit': mess = i18n.t('common.invalidRetailUnit'); break;
                                case 'Invalid UnitPacking': mess = i18n.t('common.invalidUnitPacking'); break;
                            }
						}

                        this.$store.dispatch('alert/error', mess);
                    }
				} catch (e) {
                    this.$store.dispatch('toast/displayNotification', { text: 'Error' });
                }
			},
			getProductValue(opt) {                
                this.productPackagingSpecification = opt.packagingSpecification;
			},
            async asyncFind(query, id) {
               
                if (query.length > 1) {
                    this.isLoadingProducts = true;
                    var result = await this.$productService.searchByName(query);
                    this.isLoadingProducts = false;
                    this.productList = result;
                }               
            },
		},
		mounted() {
			this.$store.dispatch("warehouse/initNewWareHouse");				           
            this.$store.dispatch("product/getProductsNameAndId");
		},
        
	}
</script>

<style scoped>
	.btn-primary {
		margin-left: 5px;
	}
</style>
